import * as s from "./checkout.styled";

import { Container } from "components/container/container.styled";
import Card from "components/card/card";
import Button from "components/button";

import { useForm } from "react-hook-form";

import FormControl from "components/form-control";
import Input from "components/input";
import FormError from "components/form-error";
import React, { useEffect, useState } from "react";

import info from "assets/images/info-icon.svg";

import axios from "axios";
import { useNavigate } from "react-router";
import Loader from "components/loader";

import {
  CardComponent,
  CardNumber,
  CardExpiry,
  CardCVV,
} from "@chargebee/chargebee-js-react-wrapper";

type FormData = {
  cardNumber: number;
  cvv: string;
  expireDate: string;
  zipCode: string;
};

interface TCheckout {
  hasScroll?: boolean;
}

const Checkout: React.FC<TCheckout> = ({ hasScroll = false }) => {
  const [guestData, setGuestData]: any = useState(null);
  const [monthlyPlan, setMonthlyPlan]: any = useState(null);
  const [defaultLoadingStatus, setDefaultLoadingStatus] = useState(false);
  const [loadingStatus, setLoadingStatus] = useState(false);
  const [cardNumberValidation, setCardNumberValidation] = useState(true);
  const [expireDateValidation, setExpireDateValidation] = useState(true);
  const [cvvValidation, setCvvValidation] = useState(true);
  const [monthValidation, setMonthValidation] = useState(true);
  const [errorMessage, setErrorMessage] = useState("");
  const [zipCode, setZipCode] = useState();
  const [zipCodeError, setZipCodeError] = useState(true);
  const [zipCodeLengthError, setZipCodeLengthError] = useState(true);

  const [noPromo, setNoPromo] = useState(
    true || window.location.href.includes("hppdf999")
  );

  const [state, setState]: any = useState({
    errors: {},
    errorMessage: "",
    // CSS class names for field's status
    classes: {
      focus: "focus-css-class",
      complete: "complete-css-class",
      invalid: "invalid-css-class",
      empty: "empty-css-class",
    },
    // Google Fonts and other whitelisted fonts
    // fonts: ["https://fonts.googleapis.com/css?family=Open+Sans"],
    // Style customizations
    styles: {
      base: {
        color: "black",
        fontWeight: 600,
        height: "40px",
        padding: "11px 10px",
        fontSize: "14px",
        outline: "none",
        borderRadius: "5px",
        border: "none",
        opacity: 1,
        ":focus": {
          color: "black",
        },

        "::placeholder": {
          color: "#6D6D6D",
          opacity: 1,
        },

        // ":focus::placeholder": {
        //   color: "black",
        // },
      },
      invalid: {
        color: "#f44336",
        ":focus": {
          color: "#f44336",
        },
        "::placeholder": {
          color: "#f44336",
        },
      },
    },
  });

  const navigate = useNavigate();

  useEffect(() => {

    if (!hasScroll) {
      const localUserData = JSON.parse(
        localStorage.getItem("guestData") as string
      );

      if (localUserData) {
        window.scrollTo(0, 0);
        (localUserData as any).vehicleMileage = parseInt(
          localUserData?.vehicleMileage
        );
        setGuestData(localUserData);

        // setValue(
        //   "zipCode",
        //   (JSON.parse(localStorage.getItem("guestData") as string as any)?.zipCode || 0)
        // );
      }
    } else {
      prepareForm();
      (
        (document as any).getElementById("activationForm1") as any
      )?.addEventListener("click", (e: any) => {
        prepareForm();
      });
    }

    setDefaultLoadingStatus(true);
    axios
      .get("/api/Item?itemId=haspro_plan")
      .then(function (response) {
        setDefaultLoadingStatus(false);
        setMonthlyPlan(
          response.data.data?.itemPrice.find(
            (item: any) => item.id === "Haspro_A"
          )
        );
      })
      .catch(function (error) {
        setDefaultLoadingStatus(false);
      });
  }, []);

  const prepareForm = () => {
    const localUserData = JSON.parse(
      localStorage.getItem("guestData") as string
    );

    (localUserData as any).vehicleMileage = parseInt(
      localUserData?.vehicleMileage
    );
    setGuestData(localUserData);

    document
      .getElementById("checkout-form")
      ?.scrollIntoView({ behavior: "smooth" });
  };

  const {
    register,
    setValue,
    getValues,
    watch,
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<FormData>();

  useEffect(() => {
    if (!cardNumberValidation) {
      const d = getValues().cardNumber as any;
      if (d.includes("_")) {
        setCardNumberValidation(false);
      } else {
        setCardNumberValidation(true);
      }
    }
  }, [watch("cardNumber")]);

  useEffect(() => {
    if (!cvvValidation) {
      const d = getValues().cvv as any;
      if (d.includes("_")) {
        setCvvValidation(false);
      } else {
        setCvvValidation(true);
      }
    }
  }, [watch("cvv")]);

  useEffect(() => {
    if (!zipCodeError || !zipCodeLengthError) {
      const d = getValues().zipCode as any;
      if (!d) {
        setZipCodeError(false);
      } else {
        if (d.split("_")[0] === "") {
          setZipCodeError(false);
        } else {
          setZipCodeError(true);
          if (d.includes("_")) {
            setZipCodeLengthError(false);
          } else {
            setZipCodeLengthError(true);
          }
        }
      }
    }
  }, [watch("zipCode")]);

  useEffect(() => {
    if (!cardNumberValidation) {
      const d = getValues().expireDate as any;

      if (d.includes("_")) {
        setExpireDateValidation(false);
      } else {
        setExpireDateValidation(true);
      }
    }

    if (!monthValidation && cardNumberValidation) {
      const d = getValues().expireDate as any;
      if (+d?.split("/")[0] > 12) {
        setMonthValidation(false);
      } else {
        setMonthValidation(true);
      }
    } else {
      setMonthValidation(true);
    }
  }, [watch("expireDate")]);

  const Procced = (token: any) => {
    let data = {
      customer: {
        ...guestData,
        zipCode: getValues().zipCode?.split("_")[0]
      },
      card: {
        tokenId: token,
      },
      couponName: noPromo ? "" : "promo",
    };
    
    axios
      .post("/api/SubscriptionChargeBee/Procced", data)
      .then(function (response) {
        setLoadingStatus(false);
        navigate(`/checkout-success?c_id=${response.data.data.id}`);

      })
      .catch(function (error) {
        setLoadingStatus(false);
        if (error.response?.data.message.includes("card number")) {
          setErrorMessage("Invalid card number.");
        } else if (error.response?.data.message.includes("Expired")) {
          setErrorMessage("Expired Card.");
        } else {
          setErrorMessage(
            error.response?.data.message || error.response?.data.title
          );
        }
      });
  };

  const cardRef = React.createRef();

  const getZipCode = (e: any) => {
    setZipCode(e.zipCode);
  };

  const onSubmit1 = (e: any) => {
    if (e) e.preventDefault();

    const d = getValues().zipCode as any;
    if (!d) {
      setZipCodeError(false);
      return;
    } else {
      setZipCodeError(true);
    }

    if (getValues().zipCode.includes("_")) {
      setZipCodeLengthError(false);
      return;
    } else {
      setZipCodeLengthError(true);
    }
    if (cardRef) {
      // Call tokenize method on card element
      setLoadingStatus(true);
      // clearTimeout(timer);
      // timer = setTimeout(() => {
        (cardRef?.current as any).tokenize().then((data: any) => {
          console.log("chargebee token", data.token);
          // setPaymentToken(data.token);
          // onSubmit(data.token);
          Procced(data.token);
        });
      // }, 300);

    }
  };

  const onChange = (status: any) => {
    // console.log(status);
    // status.error.message
    // let errors = {
    //   ...state.errors,
    //   [status.field]: status?.error?.message || ''
    // };
    // let errMessages = Object.values(errors).filter((message) => !!message);
    // console.log(errMessages, 234, errors);
    // console.log( 234, state);
    // setCErrors({
    //   errorMessage: status?.error?.message || ''
    // })
    // setState({
    //   ...state,
    //   // errors: {
    //   //   ...state.errors,
    //   //   [status.field]: status?.error?.message || ''
    //   // },
    //   errorMessage: status?.error?.message || "",
    // });
  };

  const onReady = (el: any) => {
    // el.focus();
  };

  const { fonts, styles, classes, locale } = state;

  return (
    <>
      {defaultLoadingStatus ? <Loader /> : ""}
      {loadingStatus ? (
        <Loader
          nextDescription="Almost there..."
          description="You made a great decision, it will just take a few more seconds"
        />
      ) : (
        ""
      )}
      <s.Checkout>
        <Container width="533px">
          {/* <s.BillingInfo>
            <s.Limited>Get 60% off! - Limited Time Offer</s.Limited>
            <s.CenteredTitle>Don't Miss Out!</s.CenteredTitle>
          </s.BillingInfo> */}
          {/* <s.CenteredTitle>Add your billing info</s.CenteredTitle> */}
          <s.CenteredDescription>
            <strong>
              Confirm your info and add your payment method below:
            </strong>
          </s.CenteredDescription>
          <Card>
            <s.CheckoutSubtitle>Your Information</s.CheckoutSubtitle>
            <s.InformationList>
              <s.InformationListItem>
                <s.Text color="light">Full Name:</s.Text>
                <s.Text>
                  {guestData?.firstName + " " + guestData?.lastName}
                </s.Text>
              </s.InformationListItem>

              <s.InformationListItem>
                <s.Text color="light">Vehicle:</s.Text>
                <s.Text>
                  {guestData?.vehicleYear +
                    (guestData?.brandName
                      ? " " + guestData?.brandName + " " + guestData?.modelName
                      : "")}
                </s.Text>
              </s.InformationListItem>

              <s.InformationListItem>
                <s.Text color="light">Mileage:</s.Text>
                <s.Text>
                  {(+guestData?.vehicleMileage).toLocaleString("en-US") +
                    " miles"}
                </s.Text>
              </s.InformationListItem>

              <s.InformationListItem>
                <s.Text color="light">Phone Number:</s.Text>
                <s.Text>{guestData?.phone}</s.Text>
              </s.InformationListItem>

              <s.InformationListItem>
                <s.Text color="light">Email:</s.Text>
                <s.Text>{guestData?.email}</s.Text>
              </s.InformationListItem>

              {/* <s.InformationListItem>
                <s.Text color="light">Zip Code:</s.Text>
                <s.Text>{guestData?.zipCode.split("_")[0]}</s.Text>
              </s.InformationListItem> */}
            </s.InformationList>
          </Card>
          <Card>
            <s.CheckoutSubtitle>Your Plan</s.CheckoutSubtitle>
            <s.PlanWrapper>
              <div>
                <s.Text>{monthlyPlan?.name}</s.Text> <br />
                {/* <s.Text color="light">Free trial ends 2/20/22</s.Text> */}
              </div>
              <div>
                <s.Text>
                  <strong>${+monthlyPlan?.price / 100}/mo</strong>
                </s.Text>
              </div>
            </s.PlanWrapper>
            {/* <s.ChangePlan color="light">Change plan</s.ChangePlan> */}
          </Card>

          <Card>
            <s.PlanWrapper>
              <s.CheckoutSubtitle>Total Due Today</s.CheckoutSubtitle>
              <s.TotalDueAmount>{noPromo? '$9.99' : '$0.00'}</s.TotalDueAmount>
            </s.PlanWrapper>
          </Card>

          <Card>
            <div className="cell example example3" id="example-3">
              <s.CheckoutForm onSubmit={handleSubmit(getZipCode)}>
                <div className="fieldset">
                  <CardComponent
                    className="field"
                    fonts={fonts}
                    classes={classes}
                    locale={locale}
                    styles={styles}
                    ref={cardRef}
                    onReady={onReady}
                  >
                    <s.CardNumberLabel title="Add your credit card number">
                      Card Number
                      <img src={info} />
                    </s.CardNumberLabel>

                    <FormControl>
                      <s.InputWrapper>
                        <CardNumber
                          placeholder="Card Number"
                          className="field empty"
                          onChange={onChange}
                          onReady={onReady}
                        />
                      </s.InputWrapper>
                    </FormControl>

                    <s.ThreeInputGroup>
                      <FormControl>
                        <s.IconInputWrapper>
                          <CardCVV
                            placeholder="CVV"
                            className="field empty"
                            onChange={onChange}
                          />
                          <s.InputIcon title="Please enter code on the front or back of your card">
                            <img src={info} />
                          </s.InputIcon>
                        </s.IconInputWrapper>
                      </FormControl>

                      <FormControl>
                        <s.InputWrapper>
                          <CardExpiry
                            placeholder="MM / YY"
                            className="field empty"
                            onChange={onChange}
                          />
                        </s.InputWrapper>
                      </FormControl>

                      <FormControl>
                        <Input
                          label="Zip Code"
                          mask="zip"
                          name={"zipCode"}
                          register={register}
                          type="text"
                          validate={{
                            required: "Zip Code is required",
                          }}
                        />
                      </FormControl>
                    </s.ThreeInputGroup>
                    {!zipCodeError && (
                      <>
                        <FormError>Zip Code is required</FormError>
                        <br />
                      </>
                    )}
                    {!zipCodeLengthError && (
                      <>
                        <FormError>Min. Zip Code length is 5</FormError>
                        <br />
                      </>
                    )}
                  </CardComponent>
                </div>
                <Button variant="primary" type="submit" onClick={onSubmit1}>
                  Buy Now
                </Button>
              </s.CheckoutForm>
            </div>
          </Card>

          <Card>
            <s.PlanWrapper>
              {noPromo ? (
                <>
                  <s.LightText>
                    You will be charged $9.99/mo on a recurring basis. Your
                    subscription will continue until you cancel. To cancel,
                    visit our support page. 
                    <br/>
                    <br/>By selecting “Buy Now”, you agree to
                    the above terms.
                  </s.LightText>
                </>
              ) : (
                <>
                  <s.Text>
                    <strong>
                      After any free trial(s) and/or discount(s), you will be
                      charged ${+monthlyPlan?.price / 100}/mo on a recurring
                      basis.
                    </strong>
                  </s.Text>
                  <s.LightText>
                    If you do not cancel during the free trial period, your
                    subscription will continue until you cancel. To cancel,
                    visit our support page. By selecting “Buy Now”, you agree to
                    the above terms.
                  </s.LightText>
                </>
              )}
            </s.PlanWrapper>
          </Card>
        </Container>
        <s.Separator></s.Separator>
      </s.Checkout>
    </>
  );
};

export default Checkout;

// let timer: any;