import React, { useState } from 'react';

import {
  Routes,
  Route
} from "react-router-dom";

import GlobalStyles from 'styles/global';
import { ThemeProvider } from 'styled-components';
import { theme } from 'styles/theme';

import Header from 'components/header';
import Footer from 'components/footer';
import Landing from 'pages/landing';
import Checkout from 'pages/checkout';
import CheckoutSuccess from 'pages/checkoutSuccess';
import Login from 'pages/auth/login';
import SetEmail from 'pages/auth/setEmail';
import ResetPassword from 'pages/auth/resetPassword';
import UserCabinet from 'pages/user-cabinet';
import Contract from 'pages/contract';

import { useEffect } from 'react';
import { useNavigate } from 'react-router';

import SetPassword from 'pages/auth/setPassword';

import axios from "axios";
import errorInterceptor from "shared/interceptors/error.interceptor";
import { ToastContainer } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';

import UnbounceLanding from "pages/landingUnbounce"
import UnbounceLandingV2 from "pages/landingUnbounce-v2"

axios.defaults.baseURL = process.env.REACT_APP_BASE_URL;

errorInterceptor(axios);

function App() {
  // const [fbPixel, setFbPixel] = useState<any>(null);
  // const location = useNavigate();

  // useEffect(() => {
  //   import('react-facebook-pixel')
  //   .then((x) => x.default)
  //   .then((ReactPixel) => {
  //     ReactPixel.init('220657296006131') // facebookPixelId
  //     ReactPixel.pageView();

  //     setFbPixel(ReactPixel);
  //   })
  // },[])

  // useEffect(() => {
  //   if(fbPixel){
  //     if(window.location.pathname==="/checkout"){
  //       fbPixel.track('Lead');
  //     } else if(window.location.pathname==="/checkout-success"){
  //       fbPixel.track('Purchase');
  //     }
  //   }
  // }, [location, fbPixel])


  return (
    <ThemeProvider theme={theme}>
      <GlobalStyles />
      <Header></Header>
      <ToastContainer />
      <main>
        <Routes>
          {/* <Route path="/" element={<Landing />} /> */}
          <Route path="/" element={<UnbounceLandingV2 />} />
          {/* <Route path="/hppdf999" element={<UnbounceLanding />} />
          <Route path="/vs002" element={<UnbounceLandingV2 />} /> */}
          {/* <Route path="/vs003" element={<UnbounceLandingV2 />} /> */}
          {/* <Route path="/vs002/hppdf999" element={<UnbounceLandingV2 />} /> */}
          {/* <Route path="/blcrpt001" element={<Landing />} />
          <Route path="/blcrpt001/checkout" element={<Checkout />} />
          <Route path="/blcrpt001/checkout-success" element={<CheckoutSuccess title="Thank you!" description="A confirmation email has been sent." />} />
          <Route path="/blcrpt001/login" element={<Login />} />
          <Route path="/blcrpt001/reset-password-step1" element={<SetEmail />} />
          <Route path="/blcrpt001/reset-password" element={<ResetPassword />} />
          <Route path="/blcrpt001/reset-password-again" element={<SetEmail />} />
          <Route path="/blcrpt001/set-password" element={<SetPassword />} />
          <Route path="/blcrpt001/email-success/:email" element={<CheckoutSuccess title="Instruction sent to" description="Please follow it to reset your password" hideForm="true" />} />
          <Route path="/blcrpt001/user-cabinet" element={ <UserCabinet /> } />
          <Route path="/blcrpt001/contract" element={ <Contract /> } /> */}

          <Route path="/checkout" element={<Checkout />} />
          <Route path="/hppdf999/checkout" element={<Checkout />} />
          <Route path="/checkout-success" element={<CheckoutSuccess title="Thank you!" description="A confirmation email has been sent." />} />
          <Route path="/login" element={<Login />} />
          <Route path="/reset-password-step1" element={<SetEmail />} />
          <Route path="/reset-password" element={<ResetPassword />} />
          <Route path="/reset-password-again" element={<SetEmail />} />
          <Route path="/set-password" element={<SetPassword />} />
          <Route path="/email-success/:email" element={<CheckoutSuccess title="Instruction sent to" description="Please follow it to reset your password" hideForm="true" />} />
          <Route path="/user-cabinet" element={ <UserCabinet /> } />
          <Route path="/contract" element={ <Contract /> } />
        </Routes>
      </main>
      <Footer></Footer>
    </ThemeProvider>
  );
}

export default App;