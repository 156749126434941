import * as s from "./comparisonChart.styled";
import Title from "components/title/title";
import Container from "components/container/container";
import ApplicationForm from "../application-form";
import Checkout from "pages/checkout/checkout";
import Icon from "components/icons";
import { useEffect, useState } from "react";
import Subtitle from "components/subtitle";

const ComparisonChart: React.FC = () => {
  const [isPriceListActive, setIsPriceListActive] = useState(false);

  return (
    <s.ComparisonChart>
      <Container>
        <Subtitle>HasPro Customers Save Thousands</Subtitle>
        <s.TableWrapper>
          <s.Table>
            <thead>
              <tr>
                <s.Th>Parametrs</s.Th>
                <s.Th>Leading Roadside Provider</s.Th>
                <s.Th>Leading Warranty Company </s.Th>
                <s.Th>No Warranty</s.Th>
                <s.Th>HasPro</s.Th>
              </tr>
            </thead>

            <tbody>
              {data.map(
                (
                  { parameter, provider, company, noWarranty, haspro },
                  index
                ) => (
                  <tr key={"compare-" + index}>
                    <s.Td>{parameter}</s.Td>
                    <s.Td>
                      {provider ? <Icon.CheckCircle /> : <Icon.TimesCircle />}
                    </s.Td>
                    <s.Td>
                      {company ? <Icon.CheckCircle /> : <Icon.TimesCircle />}
                    </s.Td>
                    <s.Td>
                      {noWarranty ? <Icon.CheckCircle /> : <Icon.TimesCircle />}
                    </s.Td>
                    <s.Td>
                      {haspro ? <Icon.CheckCircle /> : <Icon.TimesCircle />}
                    </s.Td>
                  </tr>
                )
              )}
            </tbody>
          </s.Table>
        </s.TableWrapper>
      </Container>

      <Container>
        <s.Accordion>
        <s.AccordionHeader className={`${isPriceListActive ? "rotate90" : ""}`} onClick={()=>setIsPriceListActive(!isPriceListActive)}>
          Breakdown of cost <br className="show-in-mobile"/> analysis savings
          <s.Rotate rotate={isPriceListActive}>
          <Icon.AngleDown/>
          </s.Rotate>
        </s.AccordionHeader>
        <s.AccordionBody className={`${isPriceListActive ? "active" : ""}`}>
          <s.TableWrapper>
            <s.Table>
              <thead>
                <tr>
                  <s.Th>Parametrs</s.Th>
                  <s.Th>Leading Roadside Provider</s.Th>
                  <s.Th>Leading Warranty Company </s.Th>
                  <s.Th>No Warranty</s.Th>
                  <s.Th>HasPro</s.Th>
                </tr>
              </thead>

              <tbody>
                {priceData.map(
                  (
                    { parameter, provider, company, noWarranty, haspro },
                    index
                  ) => (
                    <tr key={"compare-" + index}>
                      <s.Td>{parameter}</s.Td>
                      <s.Td wrap="no" mode="light">{provider}</s.Td>
                      <s.Td wrap="no" mode="light"> {company}</s.Td>
                      <s.Td wrap="no" mode="light">{noWarranty}</s.Td>
                      <s.Td wrap="no" mode="primary">{haspro}</s.Td>
                    </tr>
                  )
                )}
              </tbody>
            </s.Table>
          </s.TableWrapper>
        </s.AccordionBody>
        </s.Accordion>
      </Container>
    </s.ComparisonChart>
  );
};

export default ComparisonChart;

const data = [
  {
    parameter: "Vehicle Concierge Advisor - Great Value",
    provider: true,
    company: false,
    noWarranty: true,
    haspro: true,
  },
  {
    parameter: "No Claims Denial ",
    provider: false,
    company: false,
    noWarranty: false,
    haspro: true,
  },
  {
    parameter: "Vehicle Concierge Advisor - Great Value",
    provider: false,
    company: false,
    noWarranty: false,
    haspro: true,
  },
  {
    parameter: "Electrical",
    provider: false,
    company: true,
    noWarranty: false,
    haspro: true,
  },
  {
    parameter: "Windshield Glass",
    provider: false,
    company: false,
    noWarranty: false,
    haspro: true,
  },
  {
    parameter: "Trip Interruption",
    provider: false,
    company: true,
    noWarranty: false,
    haspro: true,
  },
  {
    parameter: "Towing",
    provider: true,
    company: true,
    noWarranty: false,
    haspro: true,
  },
  {
    parameter: "Fuel Delivery",
    provider: true,
    company: true,
    noWarranty: false,
    haspro: true,
  },
  {
    parameter: "Car Rental",
    provider: false,
    company: true,
    noWarranty: false,
    haspro: true,
  },
  {
    parameter: "Lockout Services",
    provider: true,
    company: true,
    noWarranty: false,
    haspro: true,
  },
  {
    parameter: "Brake System",
    provider: false,
    company: true,
    noWarranty: false,
    haspro: true,
  },
  {
    parameter: "Flat Tire Replacement",
    provider: true,
    company: false,
    noWarranty: false,
    haspro: true,
  },
];

const priceData = [
  {
    parameter: "Vehicle Concierge Advisor - Great Value",
    provider: "$ 480.00",
    company: "$ 5,100.00",
    noWarranty: "$ 0.00",
    haspro: "$ 600.00",
  },
  {
    parameter: "No Claims Denial ",
    provider: "$ 1,750.00",
    company: "$ 1,750.00",
    noWarranty: "$ 1,750.00",
    haspro: "$ 0.00",
  },
  {
    parameter: "Vehicle Concierge Advisor - Great Value",
    provider: "$ 1,500.00",
    company: "$ 300.00",
    noWarranty: "$ 1,750.00",
    haspro: "$ 1,300.00",
  },
  {
    parameter: "Electrical",
    provider: "$ 0.00",
    company: "$ 1,500.00",
    noWarranty: "$ 0.00",
    haspro: "$ 0.00",
  },
  {
    parameter: "Windshield Glass",
    provider: "$ 1,750.00",
    company: "$ 8,650.00",
    noWarranty: "$ 3,250.00",
    haspro: "$ 1,900.00",
  },
]