import * as s from "./checkout-success.styled";

import { Container } from "components/container/container.styled";
import Title from "components/title/title";
import Description from "components/description/description";
import Subtitle from "components/subtitle";
import Card from "components/card/card";
import Button from "components/button";

import { useForm } from "react-hook-form";

import FormControl from "components/form-control";
import Input from "components/input";
import Select from "components/select";
import FormError from "components/form-error";
import { useEffect, useState } from "react";

import CheckoutSuccessBk from "assets/images/checkout-success-bk.png";

import axios from "axios";
import { useNavigate, useParams } from "react-router";
import Loader from "components/loader";

type FormData = {
  password: string;
  confirmPassword: string;
};

interface TSuccess {
  [otherProps: string]: any;
}

const CheckoutSuccess: React.FC<TSuccess> = ({ ...props }) => {
  let { email } = useParams();
  const [guestData, setGuestData]: any = useState(null);
  const [errorMsg, setErrorMsg]: any = useState(null);
  const [confirmationErrorMsg, setConfirmationErrorMsg]: any = useState(null);
  const navigate = useNavigate();
  const [loadingStatus, setLoadingStatus] = useState(false);

  useEffect(() => {
    (window as any).dataLayer.push({'event': 'checkout_complete'});
    let cachedGuestData = localStorage.getItem("guestData");
    if(cachedGuestData != undefined && cachedGuestData.length) {
      setGuestData(JSON.parse(cachedGuestData as string));
    }
  }, []);

  const {
    register,
    setValue,
    control,
    watch,
    handleSubmit,
    formState: { errors },
  } = useForm<FormData>();

  useEffect(() => {
    if (confirmationErrorMsg) {
      setConfirmationErrorMsg(false);
    }
  }, [watch("confirmPassword")]);

  const onSubmit = (data: any) => {
    if (data.password !== data.confirmPassword) {
      setConfirmationErrorMsg(true);
      return;
    }

    const reqData = {
      customerId: guestData?.customerId,
      email: guestData?.email,
      firstName: guestData?.firstName,
      lastName: guestData?.lastName,
      phone: guestData?.phone,
      password: data.password,
    };
    if (checkPassword(data.password)) {
      setLoadingStatus(true);
      axios
        .post("/api/auth/create", reqData)
        .then(function (response) {
          setLoadingStatus(false);
          navigate(window.location.href.includes('blcrpt001')?"/blcrpt001/login":"/login");
        })
        .catch(function (error) {
          setLoadingStatus(false);
        });
    }
  };

  const checkPassword = (str: any) => {
    if (!new RegExp(/^(?=.*[a-z])/gm).test(str)) {
      setErrorMsg("Password not includes lowercase letter");
      return false;
    } else if (!new RegExp(/\d/).test(str)) {
      setErrorMsg("Password not includes number");
      return false;
    } else {
      setErrorMsg(null);
      return true;
    }
  };

  return (
    <>
      {loadingStatus ? <Loader /> : ""}
      <s.Checkout>
        <Container width="533px">
          <Card>
            <img src={CheckoutSuccessBk} alt="" />
            <s.CheckoutSubtitle>
              {props.title + " " + (email || "")}
            </s.CheckoutSubtitle>
            <s.Text>{props.description}</s.Text>

            {props.hideForm ? (
              ""
            ) : (
              <s.CheckoutForm onSubmit={handleSubmit(onSubmit)}>
                <s.CardNumberLabel>
                  To complete the registration process, please create an online
                  account by setting a password below:
                </s.CardNumberLabel>
                <FormControl>
                  <Input
                    label="Password"
                    name={"password"}
                    register={register}
                    type="password"
                    validate={{
                      required: "Password is required",
                      minLength: {
                        value: 8,
                        message: "Min. length is 8 character",
                      },
                      pattern: {
                        value: /^(?=.*[A-Z])/gm,
                        message: "Password not includes uppercase letter",
                      },
                    }}
                  />
                  {errors.password && (
                    <FormError>{errors.password.message}</FormError>
                  )}

                  {!errors.password?.message && errorMsg ? (
                    <FormError>{errorMsg}</FormError>
                  ) : (
                    ""
                  )}
                </FormControl>

                <FormControl>
                  <Input
                    label="Confirm Password"
                    name={"confirmPassword"}
                    register={register}
                    type="password"
                    validate={{
                      required: "Confirm Password is required",
                    }}
                  />

                  {errors.confirmPassword && (
                    <FormError>{errors.confirmPassword.message}</FormError>
                  )}

                  {!errors.password?.message && confirmationErrorMsg ? (
                    <FormError>Not match!</FormError>
                  ) : (
                    ""
                  )}
                </FormControl>
                <Button variant="primary" type="submit">
                  Confirm
                </Button>
              </s.CheckoutForm>
            )}
          </Card>
        </Container>
        <s.Separator></s.Separator>
      </s.Checkout>
    </>
  );
};

export default CheckoutSuccess;
