import * as s from "./user-cabinet.styled";

import { Container } from "components/container/container.styled";
import Title from "components/title/title";
import Description from "components/description/description";
import Subtitle from "components/subtitle";
import Card from "components/card/card";
import Button from "components/button";

import { useForm } from "react-hook-form";

import FormControl from "components/form-control";
import Input from "components/input";
import Select from "components/select";
import FormError from "components/form-error";
import React, { useEffect, useState } from "react";
import Loader from "components/loader";

import paypal from "assets/images/paypal.png";
import creditCard from "assets/images/card-icon.png";
import cards from "assets/images/cards.png";
import info from "assets/images/info-icon.svg";

import EditIcon from "assets/icons/edit-icon.png";
import CancelIcon from "assets/icons/cancel-icon.png";

import axios from "axios";
import { useNavigate } from "react-router";

import {
  CardComponent,
  CardNumber,
  CardExpiry,
  CardCVV
} from "@chargebee/chargebee-js-react-wrapper";

// import ImageUploading from "react-images-uploading";

type FormData = {
  cardNumber: string;
  cvv: string;
  expireDate: string;
  year: number;
  first_name: string;
  last_name: string;
  phone: string;
  email: string;
  zip: string;
  zipCode: string;
  // birthDate: any;
  vehicleMileage: string;
  // vinCode: string;
  vehicleBrandName: string;
  vehicleModelName: string;
  errorMessages: object;
};

type VerhicleData = {
  vehicleYear: string;
  vehicleMileage: string;
  // vinCode: string;
  brandName: string;
  modelName: string;
  fullName: string;
  phoneNumber: string;
  email: string;
  zipCode: string;
  firstName?: string;
  lastName?: string;
  // birthDate: string;
};

type CardData = {
  cardNumber: string;
};

const UserCabinet: React.FC = () => {
  const [cardData, setCardData] = useState<any>({});
  const [userData, setUserData] = useState<VerhicleData>({
    vehicleYear: "0000",
    vehicleMileage: "0000",
    // vinCode: "0000000",
    brandName: "xxxxx",
    modelName: "xxxxx",
    // company: 'xxxxxx',
    fullName: "xxxxx",
    phoneNumber: "xxxx",
    email: "xxxx",
    zipCode: "xxxx",
    // birthDate: "xxxxxxx",
  });
  const [guestData, setGuestData]: any = useState(null);
  const [monthlyPlan, setMonthlyPlan]: any = useState(null);
  const [personalDataEditStatus, setPersonalDataEditStatus] = useState(false);
  const [cardDataEditStatus, setCardDataEditStatus] = useState(false);
  const [isConfirmPopupActive, setIsConfirmPopupActive] = useState(false);
  const [isConfirmSuccessPopupActive, setIsConfirmSuccessPopupActive] =
    useState(false);
  const [loadingStatus, setLoadingStatus] = useState(false);

  const [firstNameValidation, setFirstNameValidation] = useState(true);
  const [lastNameValidation, setLastNameValidation] = useState(true);

  const [cardNumberValidation, setCardNumberValidation] = useState(true);
  const [expireDateValidation, setExpireDateValidation] = useState(true);
  const [cvvValidation, setCvvValidation] = useState(true);
  const [monthValidation, setMonthValidation] = useState(true);
  const [zipCodeError, setZipCodeError] = useState(true);
  const [zipCodeLengthError, setZipCodeLengthError] = useState(true);
  const [phoneValidation, setPhoneValidation] = useState(true);
  const [errorMessage, setErrorMessage] = useState("");

  const [userEmail, setUserEmail] = useState(null);

  const [state, setState]: any = useState({
    errors: {},
    errorMessage: "",
    // CSS class names for field's status
    classes: {
      focus: "focus-css-class",
      complete: "complete-css-class",
      invalid: "invalid-css-class",
      empty: "empty-css-class",
    },
    // Google Fonts and other whitelisted fonts
    // fonts: ["https://fonts.googleapis.com/css?family=Open+Sans"],
    // Style customizations
    styles: {
      base: {
        color: "black",
        fontWeight: 600,
        height: "40px",
        padding: "11px 10px",
        fontSize: "14px",
        outline: "none",
        borderRadius: "5px",
        border: "none",
        opacity: 1,
        ":focus": {
          color: "black",
        },

        "::placeholder": {
          color: "#6D6D6D",
          opacity: 1,
        },

        // ":focus::placeholder": {
        //   color: "black",
        // },
      },
      invalid: {
        color: "#f44336",
        ":focus": {
          color: "#f44336",
        },
        "::placeholder": {
          color: "#f44336",
        },
      },
    },
  });

  const navigate = useNavigate();

  useEffect(() => {
    if (JSON.parse(localStorage.getItem("userData") as any)) {
      getPersonalData();
      getCardData();
      getImages();
    } else {
      navigate(window.location.href.includes('blcrpt001')?"/blcrpt001/login":"/login");
    }
  }, []);

  const {
    register,
    watch,
    setValue,
    getValues,
    handleSubmit,
    formState: { errors },
  } = useForm<FormData>();


  const {
    register: cardRegister,
    watch: cardWatch,
    setValue: cardSetValue,
    getValues: cardGetValues,
    control,
    handleSubmit: cardHandleSubmit,
    formState: { errors: cardErrors },
  } = useForm<FormData>();
  const watchShowAge = cardWatch("cardNumber");
  const watchCvv = cardWatch("cvv");
  const wExpireDate = cardWatch("expireDate");
  const watchFirstName = watch("first_name");
  const watchLastName = watch("last_name");
  const wPhone = watch("phone");
  const wZip = cardWatch("zip");

  const onSubmit = (data: any) => {
    // if (data.phone.includes("_")) {
    //   setPhoneValidation(false);
    //   return;
    // } else {
    //   setPhoneValidation(true);
    // }

    // if (data.first_name.trim().length === 0) {
    //   setFirstNameValidation(false);
    //   return;
    // } else {
    //   setFirstNameValidation(true);
    // }

    // if (data.last_name.trim().length === 0) {
    //   setLastNameValidation(false);
    //   return;
    // } else {
    //   setLastNameValidation(true);
    // }

    const reqData = {
      id: (userData as any)?.id,
      vehicleYear: data.year,
      vehicleMileage: data.vehicleMileage,
      // vinCode: data.vinCode,
      firstName: data.first_name,
      lastName: data.last_name,
      company: data.vehicleBrand,
      email: userEmail,
      phone: data.phone,
      zipCode: data.zip,
      // birthDate: data.birthDate,
    };

    setLoadingStatus(true);
    axios
      .put("/api/customer", reqData, {
        headers: {
          Authorization: `Bearer ${
            JSON.parse(localStorage.getItem("userData") as any).accessToken
              .token
          }`,
        },
      })
      .then(function (response) {
        // setLoadingStatus(false);
        getPersonalData();
        setPersonalDataEditStatus(false);
        
        getCardData();
        setCardDataEditStatus(false);
      })
      .catch(function (error) {
        setLoadingStatus(false);
        // if (error.response.status === 401) {
        //   navigate("/login");
        // }
      });
  };

  const cardOnSubmit = (token: string) => {
    const reqData = {
      customerId: (userData as any)?.id,
      itemPriceId: monthlyPlan.id,
      tokenId: token,
    };
    setLoadingStatus(true);
    axios
      .post("/api/CustomerCard", reqData, {
        headers: {
          Authorization: `Bearer ${
            JSON.parse(localStorage.getItem("userData") as any).accessToken
              .token
          }`,
        },
      })
      .then(function (response) {
        // setLoadingStatus(false);
        getCardData(false);

        const data: any = {
          id: (userData as any)?.id,
          year: userData.vehicleYear,
          vehicleMileage: userData.vehicleMileage,
          // vinCode: userData.vinCode,
          first_name: userData.firstName,
          last_name: userData.lastName,
          vehicleBrand: userData.brandName,
          email: userEmail,
          // phone: data.phone,
          phone: null,
          zip: cardGetValues().zipCode,
          // birthDate: data.birthDate,
        }

        console.log(cardGetValues().zipCode);
        
        onSubmit(data);
      })
      .catch(function (error) {
        setLoadingStatus(false);
        if (error.response?.data.message.includes("card number")) {
          setErrorMessage("Invalid card number.");
        } else if (error.response?.data.message.includes("Expired")) {
          setErrorMessage("Expired Card.");
        } else {
          setErrorMessage(error.response?.data.message);
        }
        // if (error.response.status === 401) {
        //   navigate("/login");
        // }
      });
  };

  const getPersonalData = () => {
    setLoadingStatus(true);
    axios
      .get("/api/customer/GetCurrentCustomer", {
        headers: {
          Authorization: `Bearer ${
            JSON.parse(localStorage.getItem("userData") as any).accessToken
              .token
          }`,
        },
      })
      .then(function (response) {
        setLoadingStatus(false);
        setValue("first_name", response.data.data.firstName);
        setValue("last_name", response.data.data.lastName);
        setValue("phone", response.data.data.phoneNumber);
        setValue("email", response.data.data.email);
        setUserEmail(response.data.data.email);
        cardSetValue("zipCode", response.data.data.zipCode.toString());
        
        // setValue("birthDate", response.data.data.birthDate.split('T')[0]);

        setValue("vehicleMileage", response.data.data.vehicleMileage);
        // setValue("vinCode", response.data.data.vinCode);
        setValue("vehicleBrandName", response.data.data.brandName);
        setValue("vehicleModelName", response.data.data.modelName);
        setValue("year", response.data.data.vehicleYear);
        setUserData(response.data.data);
      })
      .catch(function (error) {
        setLoadingStatus(false);
        // if (error?.response?.status === 401) {
        //   navigate("/login");
        // }
      });
  };

  const getImages = () =>{
    setLoadingStatus(true);
    axios
      .get(`/api/image/get-images`, {
        headers: {
          Authorization: `Bearer ${
            JSON.parse(localStorage.getItem("userData") as any).accessToken
              .token
          }`,
        },
      })
      .then(function (response) {
        setLoadingStatus(false);
        setImages(response.data)
      })
      .catch(function (error) {
        setLoadingStatus(false);
        // if (error?.response?.status === 401) {
        //   navigate("/login");
        // }
      });
  }

  const getCardData = (isDefault: any = true) => {
    setLoadingStatus(true);
    axios
      .post(
        "/api/SubscriptionChargeBee/GetCurrentSubscription",
        {},
        {
          headers: {
            Authorization: `Bearer ${
              JSON.parse(localStorage.getItem("userData") as any).accessToken
                .token
            }`,
          },
        }
      )
      .then(function (response) {
        if(isDefault){
          setLoadingStatus(false);
        }
        if (response?.data?.data) {
          setCardData(response.data.data);
          setMonthlyPlan(response.data.data.subscription_items[0]);
          // cardSetValue('cardNumber', response.data.data.card?.iin.toString() + '000000' + response.data.data.card?.last4.toString());
          // cardSetValue('expireDate', (response.data.data.card?.expiryMonth.toString() + '/' + response.data.data.card?.expiryYear.toString().slice(2, 4)).toString());
        }
      })
      .catch(function (error) {
        setLoadingStatus(false);
        // if (error?.response?.status === 401) {
        //   navigate("/login");
        // }
      });
  };

  const cancelPlan = () => {
    setLoadingStatus(true);
    axios
      .post(
        "/api/SubscriptionChargeBee/CancelSubscription",
        {},
        {
          headers: {
            Authorization: `Bearer ${
              JSON.parse(localStorage.getItem("userData") as any).accessToken
                .token
            }`,
          },
        }
      )
      .then(function (response) {
        setLoadingStatus(false);
        getCardData();
        setIsConfirmPopupActive(false);
        setIsConfirmSuccessPopupActive(true);
      })
      .catch(function (error) {
        setLoadingStatus(false);
        // if (error.response.status === 401) {
        //   navigate("/login");
        // }
      });
  };

  useEffect(() => {
    const subscription = watch((value, { name, type }) =>
      console.log(value, name, type)
    );
    return () => subscription.unsubscribe();
  }, [watch]);

  useEffect(() => {
    if (!phoneValidation) {
      const d = getValues().phone as any;
      if (d.includes("_")) {
        setPhoneValidation(false);
      } else {
        setPhoneValidation(true);
      }
    }
  }, [wPhone]);

  useEffect(() => {
    if (!firstNameValidation) {
      const d = getValues().first_name as any;
      if (d.includes("_")) {
        setFirstNameValidation(false);
      } else {
        setFirstNameValidation(true);
      }
    }
  }, [watchFirstName]);

  useEffect(() => {
    if (!lastNameValidation) {
      const d = getValues().last_name as any;
      if (d.includes("_")) {
        setLastNameValidation(false);
      } else {
        setLastNameValidation(true);
      }
    }
  }, [watchLastName]);

  useEffect(() => {
    const subscription = cardWatch((value, { name, type }) =>
      console.log(value, name, type)
    );
    return () => subscription.unsubscribe();
  }, [cardWatch]);

  useEffect(() => {
    if (!cardNumberValidation) {
      const d = cardGetValues().cardNumber as any;
      if (d.includes("_")) {
        setCardNumberValidation(false);
      } else {
        setCardNumberValidation(true);
      }
    }
  }, [watchShowAge]);

  useEffect(() => {
    if (!cvvValidation) {
      const d = cardGetValues().cvv as any;
      if (d.includes("_")) {
        setCvvValidation(false);
      } else {
        setCvvValidation(true);
      }
    }
  }, [watchCvv]);

  useEffect(() => {
    if (!zipCodeError||!zipCodeLengthError) {
      const d = cardGetValues().zipCode as any;
      if (!d) {
        setZipCodeError(false);
      } else {
        if(d.split('_')[0]===''){
          setZipCodeError(false);
        } else {
          setZipCodeError(true);
          if(d.includes('_')){
            setZipCodeLengthError(false);
          } else {
            setZipCodeLengthError(true);
          }
        }
      }
    }
  }, [wZip]);

  useEffect(() => {
    if (!cardNumberValidation) {
      const d = cardGetValues().expireDate as any;

      if (d.includes("_")) {
        setExpireDateValidation(false);
      } else {
        setExpireDateValidation(true);
      }
    }

    if (!monthValidation && cardNumberValidation) {
      const d = cardGetValues().cardNumber as any;
      if (+d?.split("/")[0] > 12) {
        setMonthValidation(false);
        // return;
      } else {
        setMonthValidation(true);
      }
    } else {
      setMonthValidation(true);
    }
  }, [wExpireDate]);

  const cardRef = React.createRef();

  const onSubmit1 = (e: any) => {
    if (e) e.preventDefault();
    const d = cardGetValues().zipCode as any;
    
    if (!d) {
      setZipCodeError(false);
      return;
    } else {
      setZipCodeError(true);
    }

    if(cardGetValues().zipCode.includes('_')){
      setZipCodeLengthError(false);
      return;
    } else {
      setZipCodeLengthError(true);
    }
    
    if (cardRef) {
      // Call tokenize method on card element
      console.log(cardRef?.current);

      (cardRef?.current as any).tokenize().then((data: any) => {
        console.log("chargebee token", data.token);
        // setPaymentToken(data.token);
        cardOnSubmit(data.token);
      });
    }
  };

  const onChange = (status: any) => {
    // console.log(status);
    // status.error.message
    // let errors = {
    //   ...state.errors,
    //   [status.field]: status?.error?.message || ''
    // };
    // let errMessages = Object.values(errors).filter((message) => !!message);
    // console.log(errMessages, 234, errors);
    // console.log( 234, state);
    // setCErrors({
    //   errorMessage: status?.error?.message || ''
    // })
    // setState({
    //   ...state,
    //   // errors: {
    //   //   ...state.errors,
    //   //   [status.field]: status?.error?.message || ''
    //   // },
    //   errorMessage: status?.error?.message || "",
    // });
  };

  const onReady = (el: any) => {
    el.focus();
  };

  const { fonts, styles, classes, locale } = state;

  const showContract = () => {
    window.open(`${window.location.origin}/contract`);
    // navigate("/contract");
  };

  const [images, setImages]: any = useState([]);
  const maxNumber = 69;

  const onImgChange = (imageList: any, addUpdateIndex: any) => {
    // data for submit
    setImages(imageList);

    addUpdateIndex.forEach((element: any) => {
      const reqData = {
        customerId: (userData as any)?.id,
        image: {
          content: `${imageList[element].data_url.slice(
            imageList[element].data_url.indexOf(",") + 1
          )}`,
          fileExtension: `.${imageList[element].file.type.split("/")[1]}`,
        },
      };

      axios
        .post("/api/image/add", reqData)
        .then(function (response) {
          setLoadingStatus(false);

          imageList[element].id = response.data.data;
          setImages([...imageList]);
        })
        .catch(function (error) {
          setLoadingStatus(false);
          imageList[element].id = "fail";
          setImages([...imageList]);
        });
    });
  };

  const handleImgRemove = (func: any, index: any) => {
    // func(index);
    const params = {
      id: images[index].id
    };

    axios
      .delete("/api/image/delete", {params})
      .then(function (response) {
        setLoadingStatus(false);
        func(index);
      })
      .catch(function (error) {
        setLoadingStatus(false);
      });
  };

  return (
    <>
      {loadingStatus ? <Loader /> : ""}
      <s.Checkout>
        <Container width="533px">
          <s.CenteredTitle>Your Profile</s.CenteredTitle>
          
          <s.End>
            <s.Logout onClick={() => navigate(window.location.href.includes('blcrpt001')?"/blcrpt001/login":"/login")}>Logout</s.Logout>
          </s.End>

          <Card>
            <s.EditLine>
              <s.CheckoutSubtitle>Personal Information</s.CheckoutSubtitle>
              <span
                onClick={() =>
                  setPersonalDataEditStatus(!personalDataEditStatus)
                }
              >
                {!personalDataEditStatus ? (
                  <s.Icon src={EditIcon} onClick={() =>
                    setPersonalDataEditStatus(!personalDataEditStatus)
                  }/>
                ) : (
                  <s.Icon src={CancelIcon} />
                )}
              </span>
            </s.EditLine>

            <s.InformationList
              style={{ display: !personalDataEditStatus ? "block" : "none" }}
            >
              <s.InformationListItem>
                <s.Text color="light">Full Name:</s.Text>
                <s.Text>{userData?.fullName}</s.Text>
              </s.InformationListItem>

              <s.InformationListItem>
                <s.Text color="light">Phone Number:</s.Text>
                <s.Text>{userData?.phoneNumber}</s.Text>
              </s.InformationListItem>

              <s.InformationListItem>
                <s.Text color="light">Email:</s.Text>
                <s.Text>{userData?.email}</s.Text>
              </s.InformationListItem>
{/* 
              <s.InformationListItem>
                <s.Text color="light">Zip Code:</s.Text>
                <s.Text>{userData?.zipCode}</s.Text>
              </s.InformationListItem> */}
              {/* <s.InformationListItem>
                <s.Text color="light">Birth Date:</s.Text>
                <s.Text>{userData?.birthDate.split('T')[0]}</s.Text>
              </s.InformationListItem> */}
            </s.InformationList>

            <s.CheckoutForm
              style={{ display: personalDataEditStatus ? "block" : "none" }}
              onSubmit={handleSubmit(onSubmit)}
            >
              <s.InputGroup>
                <FormControl>
                  <Input
                    label="First Name"
                    name={"first_name"}
                    register={register}
                    type="text"
                    validate={{
                      required: "First Name is required",
                      pattern: {
                        value: /^[a-zA-Z ]{2,30}$/, // /^(?=.*[A-Z])/gm
                        message: "Invalid first name",
                      },
                    }}
                  />
                  {!firstNameValidation && (
                    <FormError>First Name is required</FormError>
                  )}
                  {errors.first_name && (
                    <FormError>{errors.first_name.message}</FormError>
                  )}
                </FormControl>
                <FormControl>
                  <Input
                    label="Last Name"
                    name={"last_name"}
                    register={register}
                    type="text"
                    validate={{
                      required: "Last Name is required",
                      pattern: {
                        value: /^[a-zA-Z ]{2,30}$/, // /^(?=.*[A-Z])/gm
                        message: "Invalid last name",
                      },
                    }}
                  />
                  {!lastNameValidation && (
                    <FormError>Last Name is required</FormError>
                  )}
                  {errors.last_name && (
                    <FormError>{errors.last_name.message}</FormError>
                  )}
                </FormControl>
                <FormControl>
                  <Input
                    label="Phone Number"
                    name={"phone"}
                    mask="phone"
                    register={register}
                    type="tel"
                    required={true}
                    validate={{ required: "Phone Number is required" }}
                  />
                  {!phoneValidation && <FormError>Enter valid phone</FormError>}
                  {errors.phone && (
                    <FormError>{errors.phone.message}</FormError>
                  )}
                </FormControl>
                <FormControl>
                  <Input
                    disabled
                    label="Email"
                    name={"email"}
                    register={register}
                    type="email"
                    validate={{
                      required: "Email is required",
                      pattern: {
                        value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                        message: "invalid email address",
                      },
                    }}
                  />
                  {errors.email && (
                    <FormError>{errors.email.message}</FormError>
                  )}
                </FormControl>
                {/* <FormControl size="large">
                  <Input
                    label="Zip Code"
                    mask="zip"
                    name={"zip"}
                    register={register}
                    type="text"
                  />
                </FormControl> */}

                {/* <FormControl>
                  <Input
                    label="Date of Birth"
                    name={"birthDate"}
                    register={register}
                    type="date"
                    validate={{
                      required: "Birth Date is required",
                    }}
                  />
                  {errors.birthDate && (
                    <FormError>{errors.birthDate.message}</FormError>
                  )}
                </FormControl> */}

              </s.InputGroup>
              <Button variant="primary" type="submit">
                Save
              </Button>
            </s.CheckoutForm>
          </Card>

          <Card>
            <s.EditLine>
              <s.CheckoutSubtitle>Credit Card Info</s.CheckoutSubtitle>
              <span onClick={() => setCardDataEditStatus(!cardDataEditStatus)}>
                {!cardDataEditStatus ? (
                  <s.Icon src={EditIcon} />
                ) : (
                  <s.Icon src={CancelIcon} />
                )}
              </span>
            </s.EditLine>

            <s.InformationList
              style={{ display: !cardDataEditStatus ? "block" : "none" }}
            >
              <s.InformationListItem>
                <s.Text color="light">Card Number:</s.Text>
                <s.Text>
                  {
                  // cardData?.card?.iin?.toString() +
                    "************" +
                    cardData?.card?.last4?.toString()}
                </s.Text>
              </s.InformationListItem>
              <s.InformationListItem>
                <s.Text color="light">Expire Date:</s.Text>
                <s.Text>
                  {(
                    cardData?.card?.expiryMonth?.toString() +
                    "/" +
                    cardData?.card?.expiryYear?.toString().slice(2, 4)
                  ).toString()}
                </s.Text>
              </s.InformationListItem>

              <s.InformationListItem>
                <s.Text color="light">Zip Code:</s.Text>
                <s.Text>{userData?.zipCode}</s.Text>
              </s.InformationListItem>
            </s.InformationList>

            <div
              className="cell example example3"
              id="example-3"
              style={{ display: cardDataEditStatus ? "block" : "none" }}
            >
              <s.CheckoutForm >
                <div className="fieldset">
                  <CardComponent
                    className="field"
                    fonts={fonts}
                    classes={classes}
                    locale={locale}
                    styles={styles}
                    ref={cardRef}
                    onReady={onReady}
                  >
                    <s.CardNumberLabel title="Add your credit card number">
                      Card Number
                      <img src={info} />
                    </s.CardNumberLabel>

                    <FormControl>
                      <s.InputWrapper>
                        <CardNumber
                          placeholder="Card Number"
                          className="field empty"
                          onChange={onChange}
                          onReady={onReady}
                        />
                      </s.InputWrapper>
                      {/* <FormError>{state.errors?.number}</FormError> */}
                    </FormControl>

                    <s.ThreeInputGroup>
                      <FormControl>
                        <s.IconInputWrapper>
                          <CardCVV
                            placeholder="CVV"
                            className="field empty"
                            onChange={onChange}
                          />
                          <s.InputIcon title="Please enter code on the front or back of your card">
                            <img src={info} />
                          </s.InputIcon>
                        </s.IconInputWrapper>
                        {/* <FormError>{state.errors?.cvv}</FormError> */}
                      </FormControl>

                      <FormControl>
                        <s.InputWrapper>
                          <CardExpiry
                            placeholder="MM / YY"
                            className="field empty"
                            onChange={onChange}
                          />
                        </s.InputWrapper>
                        {/* <FormError>{state.errors?.expiry}</FormError> */}
                      </FormControl>

                      <FormControl size="large">
                      <Input
                        label="Zip Code"
                        mask="zip"
                        name={"zipCode"}
                        register={cardRegister}
                        type="text"
                        validate={{
                          required: "Zip Code is required",
                        }}
                      />
                    {!zipCodeError && (
                      <>
                        <FormError>Zip Code is required</FormError>
                      </>
                    )}
                    {!zipCodeLengthError && (
                      <>
                        <FormError>Min. Zip Code length is 5</FormError>
                      </>
                    )}
                    </FormControl>
                    </s.ThreeInputGroup>
                  </CardComponent>
                </div>
                {/* <button type="button" onClick={onSubmit1}>
                  Pay now
                </button> */}

                <Button variant="primary" type="submit" onClick={onSubmit1}>
                  Save
                </Button>
              </s.CheckoutForm>
              {/* <div id="errors">{cErrors?.errorMessage}</div> */}
            </div>
          </Card>

          <Card>
            <s.CheckoutSubtitle>Vehicle</s.CheckoutSubtitle>
            <s.InformationList>
              <s.InformationListItem>
                <s.Text color="light">Vehicle:</s.Text>
                <s.Text>
                  {userData?.vehicleYear +
                    (userData?.brandName
                      ? ", " + userData?.brandName + " " + userData?.modelName
                      : "")}
                </s.Text>
              </s.InformationListItem>

              <s.InformationListItem>
                <s.Text color="light">Mileage:</s.Text>
                <s.Text>
                  {(+userData?.vehicleMileage).toLocaleString("en-US") +
                    " miles"}
                </s.Text>
              </s.InformationListItem>

              {/* <s.InformationListItem>
                <s.Text color="light">Vin Code:</s.Text>
                <s.Text>
                  {userData?.vinCode}
                </s.Text>
              </s.InformationListItem> */}
            </s.InformationList>

           {/* <ImageUploading
                  multiple
                  value={images}
                  onChange={onImgChange}
                  maxNumber={maxNumber}
                  dataURLKey="data_url"
                >
                  {({
                    imageList,
                    onImageUpload,
                    onImageRemoveAll,
                    onImageUpdate,
                    onImageRemove,
                    isDragging,
                    dragProps,
                  }) => (
                    <div className="upload__image-wrapper">
                      {imageList.length > 0 ? (
                        <s.DropZone
                          type="button"
                          style={isDragging ? { color: "red" } : undefined}
                          {...dragProps}
                        >
                          {imageList.map((image, index) => (
                            <s.W25 key={"vinImg-" + index}>
                              <s.DropZoneImgWrapper
                                className={`image-item ${
                                  image.id === "fail" ? "load-fail" : image.id? "loaded":""
                                }`}
                              >
                                <s.Layout></s.Layout>
                                <img
                                  src={image["data_url"] || image.imageUrl}
                                  alt=""
                                  width="100"
                                />
                                {image.id ? (
                                  <s.RemoveDroppedImg
                                    onClick={() =>
                                      handleImgRemove(onImageRemove, index)
                                    }
                                  >
                                    x
                                  </s.RemoveDroppedImg>
                                ) : (
                                  ""
                                )}
                              </s.DropZoneImgWrapper>
                            </s.W25>
                          ))}
                        </s.DropZone>
                      ) : (
                        ""
                      )}
                      <Button
                        className="w-100"
                        onClick={onImageUpload}
                        variant="primary"
                        type="button"
                      >
                        Upload Photo
                      </Button>
                      &nbsp;
                    </div>
                  )}
                </ImageUploading> */}
          </Card>

          {(cardData as any)?.subscription_items ? (
            <Card>
              <s.CheckoutSubtitle>Your Plan</s.CheckoutSubtitle>
              <s.PlanWrapper>
                <div>
                  <s.Text>Haspro A</s.Text> <br />
                  <s.GreenText onClick={() => showContract()}><u>View Contract</u></s.GreenText>
                  {/* <s.Text color="light">Free trial ends 2/20/22</s.Text> */}
                </div>
                <div>
                  <s.Text>
                    {(cardData as any)?.subscription_items ? (
                      <strong>
                        $
                        {(cardData as any)?.subscription_items[0]?.amount / 100}
                        /mo
                      </strong>
                    ) : (
                      ""
                    )}
                  </s.Text>
                  <br />
                  <s.Cancel onClick={() => setIsConfirmPopupActive(true)}>
                    Cancel Plan
                  </s.Cancel>
                </div>
              </s.PlanWrapper>
              {/* <Button variant="primary" type="submit">Save</Button> */}
            </Card>
          ) : (
            <Card>
              <s.CheckoutRedSubtitle>
                You do not have subscription
              </s.CheckoutRedSubtitle>
            </Card>
          )}
        </Container>
        <s.Separator></s.Separator>

        {isConfirmPopupActive ? (
          <s.PopupWrapper>
            <Card>
              <h1>Are You Sure?</h1>
              <s.BtnGroup>
                <Button
                  variant="primary"
                  onClick={() => setIsConfirmPopupActive(false)}
                >
                  No
                </Button>
                <Button variant="warning" onClick={() => cancelPlan()}>
                  Yes
                </Button>
              </s.BtnGroup>
            </Card>
          </s.PopupWrapper>
        ) : (
          ""
        )}

        {isConfirmSuccessPopupActive ? (
          <s.PopupWrapper>
            <Card>
              <s.CheckoutSubtitle>
                You have successfully canceled your plan.
              </s.CheckoutSubtitle>
              <s.LeftDescription>
                An email confirmation has been sent.
              </s.LeftDescription>
              <s.CenteredDescription>Thank you.</s.CenteredDescription>
              <s.BtnGroup>
                <Button
                  variant="primary"
                  onClick={() => setIsConfirmSuccessPopupActive(false)}
                >
                  Continue
                </Button>
                {/* <Button variant="warning" onClick={() => cancelPlan()}>
                  Yes
                </Button> */}
              </s.BtnGroup>
            </Card>
          </s.PopupWrapper>
        ) : (
          ""
        )}
      </s.Checkout>
    </>
  );
};

export default UserCabinet;

const currenctYear = new Date().getFullYear();
const years = Array(currenctYear - 1989)
  .fill("")
  .map((v, idx) => {
    const year = (currenctYear - idx).toString();
    return { value: year, label: year };
  });

const errorMessages = {
  rerquired: "Field is required",
  email: "Please enter valid email adress",
};
